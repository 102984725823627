<script>
	import RequestForm from '$lib/components/RequestForm.svelte';
	import Section from '$lib/components/Section.svelte';
	import ActionButton from '$lib/components/ActionButton.svelte';
</script>


<Section id="main">
	<div id="request-demo" class="md:mr-5">
		<h1>Accuracy in, accuracy out</h1>
		<p>
			Let customers enter their own glass sizes and review them together in one place.
		</p>
		<p>
			Expect quotes, orders, and deliverables to be right <b>every time!</b>
		</p>
		<div class="w-full pt-8"><RequestForm /></div>
		<div class="hidden md:block">
			<img src="/images/phone-truck-lockup.png" />
		</div>
	</div>

	<div class="section-image md:basis-1/2">
		<img alt="Excel import" class="drop-shadow" src="/images/seeing-orders.svg" />
	</div>
</Section>


<Section id="money">
	<div>
		<h2>Catch mistakes before they cost you money</h2>
		<p>
			Real-time collaboration and instant shape validation will end rework.
		</p>
		<ActionButton />
	</div>
	<div class="section-image md:basis-2/5">
		<img src="/images/screen-items.png" alt="glazier" />
	</div>
</Section>

<!--
-->

<Section id="time">
	<div>
		<h2>Turn around orders faster</h2>
		<p>
			Transparent orders, free of misinterpretation.
		</p>
		<p>
			Less clarification needed; shorter lead times.
		</p>
		<p>
			Error-free shape lists, error-free glass, and loyal customers.
		</p>
		<ActionButton />
	</div>
	<div class="section-image md:basis-2/5">
		<img src="/images/screen-jobs.png" alt="fabricator" />
	</div>
</Section>

<style lang="scss">
	.yellow {
		font-weight: 400;
		font-style: normal;
		color: #ffc53d;
	}
	.section-image {
		@apply flex justify-center items-center md:shrink-0;
	}

	.section-image img {
		@apply max-h-[288px] md:max-h-max;
	}

	.drop-shadow {
		filter: drop-shadow(0px 0px 0.25px rgba(0, 0, 0, 0.7))
			drop-shadow(0px 5px 7.5px rgba(0, 0, 0, 0.3));
	}


</style>
